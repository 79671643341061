<template>
  <v-dialog v-model="addOutletDialog" scrollable width="680px">
    <v-form ref="form" v-model="valid">
      <v-card>
        <v-card-text class="border-bottom mb-3">
          <div class="row pt-1 border-bottom">
            <div class="col-md-12">
              <div class="d-flex justify-space-between align-center mt-2">
                <SvgIcon
                  class="text-2xl font-semibold"
                  :text="`${outlet.id != null ? 'Update ' : 'Add '} Outlet`"
                  style="color: black"
                >
                </SvgIcon>
                <v-btn fab x-small class="shadow-0" @click="cancelOutlet">
                  <v-icon>mdi-close</v-icon>
                </v-btn>
              </div>
            </div>
          </div>

          <v-row dense class="mt-6">
            <v-col md="3" lg="3" style="aspect-ratio: 1">
              <image-uploader
                @upload="
                  (data) => {
                    outlet.profile_image = data;
                  }
                "
                :image_path="outlet.image_path"
                defaultImage="outlet"
                @remove="
                  () => {
                    outlet.profile_image = null;
                  }
                "
                ref="image_upload"
                text="Outlet Image"
                message-text=""
                :show-guide="false"
              ></image-uploader>
            </v-col>
            <v-col md="9" lg="9">
              <v-row dense>
                <v-col cols="12" sm="6" md="6">
                  <label for="">Contact Person First name*</label>
                  <v-text-field
                    v-model="outlet.first_name"
                    outlined
                    required
                    :rules="[(v) => !!v || 'First name is required']"
                    class="q-text-field shadow-0"
                    hide-details="auto"
                    validate-on-blur
                    dense
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="6">
                  <label for="">Contact Person Last name*</label>

                  <v-text-field
                    v-model="outlet.last_name"
                    outlined
                    class="q-text-field shadow-0"
                    hide-details="auto"
                    validate-on-blur
                    dense
                    required
                    :rules="[(v) => !!v || 'Last name is required']"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="6">
                  <label for="">Outlet Name*</label>
                  <v-text-field
                    v-model="outlet.name"
                    outlined
                    class="q-text-field shadow-0"
                    hide-details="auto"
                    validate-on-blur
                    dense
                    required
                    :rules="[(v) => !!v || 'Outlet Name is required']"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="6">
                  <label for="">Email*</label>
                  <v-text-field
                    v-model="outlet.email"
                    outlined
                    class="q-text-field shadow-0"
                    dense
                    required
                    :rules="[
                      (v) => !!v || 'E-mail is required',
                      (v) => /.+@.+\..+/.test(v) || 'E-mail must be valid',
                    ]"
                    hide-details="auto"
                    validate-on-blur
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <v-row dense class="mt-4">
            <v-col cols="12">
              <div class="d-flex justify-space-between">
                <label for="">Description </label>
                <small>
                  {{
                    (outlet.description ? outlet.description.length : 0) +
                    "/200"
                  }}
                </small>
              </div>
              <v-textarea
                v-model="outlet.description"
                outlined
                class="q-text-field shadow-0"
                dense
                required
                hide-details="auto"
                validate-on-blur
                maxlength="200"
                rows="2"
              ></v-textarea>
            </v-col>
            <v-col cols="12" sm="6" md="6">
              <label for="">Phone*</label>
              <mobile-number-field
                v-model="outlet.mobile"
                outlined
                required
                :dense="true"
                :variant="2"
              ></mobile-number-field>
            </v-col>
            <v-col cols="12" sm="6" md="6">
              <label for="">Username*</label>

              <v-text-field
                v-model="outlet.username"
                outlined
                class="q-text-field shadow-0"
                hide-details="auto"
                validate-on-blur
                dense
                autocomplete="off"
                :name="`username_${Math.random()}`"
                required
                :rules="[(v) => !!v || 'Username is required']"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row dense v-if="!editFlag">
            <v-col cols="12" md="6">
              <label for="">Password*</label>

              <v-text-field
                :type="outlet.hidePassword1 ? 'text' : 'password'"
                :append-icon="outlet.hidePassword1 ? 'mdi-eye' : 'mdi-eye-off'"
                outlined
                class="q-text-field shadow-0"
                hide-details="auto"
                validate-on-blur
                dense
                v-model="outlet.password"
                @click:append="outlet.hidePassword1 = !outlet.hidePassword1"
                required
                autocomplete="new-password"
                :name="`password_${Math.random()}`"
                :rules="[(v) => !!v || 'Password is required']"
              />
            </v-col>
            <v-col cols="12" md="6">
              <label for="">Confirm password*</label>
              <v-text-field
                :type="outlet.hidePassword2 ? 'text' : 'password'"
                :append-icon="outlet.hidePassword2 ? 'mdi-eye' : 'mdi-eye-off'"
                outlined
                class="q-text-field shadow-0"
                hide-details="auto"
                dense
                :autocomplete="`outlet-password-confirm-${parseInt(
                  outlet.name
                )}`"
                v-model="outlet.password_confirm"
                @click:append="outlet.hidePassword2 = !outlet.hidePassword2"
                required
                :rules="[
                  (v) => !!v || 'Password is required',
                  (v) => v == this.outlet.password || 'Password does not match',
                ]"
              />
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="12" md="4">
              <label for="">Roles*</label>
              <v-select
                v-model="outlet.role_id"
                :items="roles"
                item-value="id"
                outlined
                :menu-props="{ bottom: true, offsetY: true }"
                class="q-autocomplete shadow-0"
                hide-details="auto"
                validate-on-blur
                dense
                item-text="title"
                :rules="[(v) => !!v || 'Role is required']"
              ></v-select>
            </v-col>
            <v-col md="4">
              <label for="">Outlet Service*</label>

              <v-select
                v-model="outlet.venue_service_ids"
                :items="venueServices"
                item-text="name"
                multiple
                item-value="venue_service_id"
                outlined
                :menu-props="{ bottom: true, offsetY: true }"
                class="q-autocomplete shadow-0"
                hide-details="auto"
                validate-on-blur
                dense
                required
                @change="fetchFacilities"
                :rules="[(v) => v.length > 0 || 'Service is required']"
              >
                <template v-slot:prepend-item>
                  <v-list-item ripple @click="toggleSelectOutlet('service')">
                    <v-list-item-action>
                      <v-icon
                        :color="
                          venueServiceIds.length > 0 ? 'indigo darken-4' : ''
                        "
                        >{{ getIconOutlet() }}</v-icon
                      >
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title>All</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </template>
                <template
                  v-if="
                    outlet.venue_service_ids &&
                    venueServices.length == outlet.venue_service_ids.length
                  "
                  v-slot:selection="{ index }"
                >
                  <span v-if="index == 0">All Services</span>
                </template>
                <template v-else v-slot:selection="{ item, index }">
                  <span v-if="index == 0">{{ item.name }}</span>
                  <span v-if="index == 1">, ...</span>
                </template>
              </v-select>
            </v-col>

            <v-col
              md="4"
              v-if="checkWritePermission($modules.outlet.commission.slug)"
            >
              <label for="">Commission</label>

              <v-text-field
                v-model="outlet.commission_percentage"
                :rules="percentageRules"
                type="text"
                required
                outlined
                class="q-text-field shadow-0"
                hide-details="auto"
                validate-on-blur
                dense
                append-icon="mdi-percent"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col md="4">
              <div class="col-md-3 d-flex gap-x-4">
                <label for="" class="d-flex text-center align-center"
                  >Enable Online</label
                >
                <v-switch
                  :false-value="0"
                  :true-value="1"
                  v-model="outlet.is_public"
                  class="d-flex text-center align-center"
                ></v-switch>
              </div>
            </v-col>
            <v-col md="4" class="mt-5">
              <v-checkbox
                v-model="outlet.enable_booking"
                class="mt-2"
                false-value="0"
                true-value="1"
                label="Enable Booking"
              ></v-checkbox>
            </v-col>
            <!-- <v-col md="4" v-if="outlet.enable_booking === '1'" class="mt-5">
              <v-select
                v-model="outlet.facility_id"
                :items="facilities"
                :menu-props="{ bottom: true, offsetY: true }"
                :rules="[(v) => !!v || 'Select a Facility']"
                background-color="#fff"
                class="q-autocomplete shadow-0"
                dense
                hide-details="auto"
                item-text="name"
                item-value="id"
                outlined
                required
              ></v-select>
            </v-col> -->

            <v-col md="4" v-if="outlet.enable_booking === '1'">
              <label for="">Facilities*</label>
              <v-select
                v-model="outlet.facility_ids"
                :items="facilities"
                item-text="name"
                multiple
                item-value="id"
                outlined
                :menu-props="{ bottom: true, offsetY: true }"
                class="q-autocomplete shadow-0"
                hide-details="auto"
                validate-on-blur
                dense
                @click="logThis"
              >
                <template v-slot:prepend-item>
                  <v-list-item ripple @click="toggleSelectOutlet('facility')">
                    <v-list-item-action>
                      <v-icon
                        :color="
                          outlet.facility_ids.length > 0
                            ? 'indigo darken-4'
                            : ''
                        "
                        >{{ getIconFacility() }}</v-icon
                      >
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title>All</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </template>
                <template
                  v-if="facilities.length == outlet.facility_ids.length"
                  v-slot:selection="{ index }"
                >
                  <span v-if="index == 0">All Facilities</span>
                </template>
                <template v-else v-slot:selection="{ item, index }">
                  <span v-if="index == 0">{{ item.name }}</span>
                  <span v-if="index == 1">, ...</span>
                </template>
              </v-select>
            </v-col>
          </v-row>
        </v-card-text>

        <v-card-actions>
          <v-btn class="ma-2" text @click="cancelOutlet">Close</v-btn>
          <v-spacer></v-spacer>

          <v-btn
            class="ma-2 white--text blue-color"
            @click="addOrUpdateOutlet"
            >{{ outlet.id != null ? "Update" : "Save" }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>
<script>
import MobileNumberField from "@/components/Fields/MobileNumberField.vue";
import SvgIcon from "@/components/Image/SvgIcon.vue";
import ImageUploader from "@/components/Image/ImageUploader.vue";

export default {
  components: { ImageUploader, SvgIcon, MobileNumberField },
  props: {
    addOutletDialog: { type: Boolean, default: false },
    roles: { type: Array, default: null },
    venueServices: { type: Array, default: null },
    venueServiceIds: { type: Array, default: null },
    outlet_ID: { type: Number, default: null },
  },

  mounted() {
    this.getFacilities();
    this.outletDetails(this.outlet_ID);
  },

  methods: {
    logThis() {
      // console.log("facilities after click");
      // console.log(this.outlet.facility_ids);
    },
    getFacilities() {
      let url = "";

      if (this.outlet.venue_service_ids.length > 0)
        url += "?venue_service_ids=" + this.outlet.venue_service_ids;
      this.$http
        .get("venues/outlets/facilities" + url)
        .then((response) => {
          if (response.status == 200) {
            this.facilities = response.data.data;

            this.facilities = response.data.data.map((facility) => ({
              ...facility,
              id: Number(facility.id),
            }));

            // // Check if the current facility_id is in the facilities array
            this.outlet.facility_ids = this.outlet.facility_ids.filter((id) =>
              this.facilities.some((facility) => facility.id === id)
            );
          }
        })
        .catch((error) => {
          this.showError(error);
        })
        .finally(() => {
          this.hideLoader();
        });
    },
    getIconOutlet() {
      if (this.outlet.venue_service_ids.length == 0)
        return "mdi-checkbox-blank-outline";
      if (this.venueServices.length == this.outlet.venue_service_ids.length)
        return "mdi-close-box";
      return "mdi-minus-box";
    },
    getIconFacility() {
      if (this.outlet.facility_ids.length == 0)
        return "mdi-checkbox-blank-outline";
      if (this.facilities.length == this.outlet.facility_ids.length)
        return "mdi-close-box";
      return "mdi-minus-box";
    },
    toggleSelectOutlet(type = "service") {
      if (type == "service") {
        this.$nextTick(() => {
          if (
            this.venueServices.length == this.outlet.venue_service_ids.length
          ) {
            this.outlet.venue_service_ids = [];
          } else {
            this.outlet.venue_service_ids = this.venueServices.map(
              (item) => item.venue_service_id
            );
          }
        });
      } else if (type == "facility") {
        this.$nextTick(() => {
          if (this.facilities.length == this.outlet.facility_ids.length) {
            // Deselect all facilities
            this.outlet.facility_ids = [];
          } else {
            // Select all facilities
            this.outlet.facility_ids = this.facilities.map((item) =>
              Number(item.id)
            );
          }
        });
      }
    },

    fetchFacilities() {
      this.getFacilities();
    },
    outletDetails(id) {
      if (!id) return;
      this.$http
        .get(`venues/outlets/${id}`)
        .then((response) => {
          if (response.status == 200 && response.data.status == true) {
            this.outlet = response.data.data;
            this.outlet.status_id = this.outlet.status_id.toString();
            this.outlet.profile_image = this.outlet.image_path
              ? this.outlet.image_path
              : null;
            this.outlet.venue_service_ids = this.outlet.outlet_services.map(
              (item) => item.venue_service_id
            );

            if (this.outlet.facility.length > 0) {
              this.outlet.facility_ids = []; // Initialize or reset facility_ids array

              // Loop through each facility in the array and push its id to facility_ids
              this.outlet.facility.forEach((facility) => {
                const facilityExists = this.facilities.some(
                  (f) => f.id === facility.id
                );

                // If the facility exists in the facilities array, push its id to facility_ids
                if (facilityExists) {
                  this.outlet.facility_ids.push(facility.id);
                }
              });
            } else this.outlet.facility_ids = [];

            this.outlet.enable_booking = this.outlet.enable_booking.toString();
            this.getFacilities();
            this.addOutletDialog = true;
            this.editFlag = true;
            this.$forceUpdate();
          }
        })
        .catch((error) => {
          this.errorChecker(error);
        });
    },
    addOrUpdateOutlet(e) {
      if (e != null) {
        e.preventDefault();
        if (!this.$refs.form.validate()) {
          this.showError("Please fill all required fields");
          return;
        }
      }

      let formData = new FormData();
      this.showLoader();
      if (
        this.outlet.profile_image != null &&
        typeof this.outlet.profile_image != "string"
      )
        formData.append("image_path", this.outlet.profile_image);
      formData.append("is_public", this.outlet.is_public);
      formData.append("name", this.outlet.name);
      formData.append("first_name", this.outlet.first_name);
      formData.append("last_name", this.outlet.last_name);
      formData.append("email", this.outlet.email);
      formData.append("mobile", this.outlet.mobile);
      formData.append("username", this.outlet.username);
      formData.append("description", this.outlet.description);
      formData.append("enable_booking", this.outlet.enable_booking);
      if (this.outlet.enable_booking == 1)
        formData.append("facility_ids", this.outlet.facility_ids);
      formData.append(
        "commission_percentage",
        this.outlet.commission_percentage
      );
      if (this.outlet.id) {
        formData.append("outlet_id", this.outlet.id);
      }

      if (this.outlet.password)
        formData.append("password", this.outlet.password);
      if (this.outlet.role_id) formData.append("role_id", this.outlet.role_id);

      formData.append("status_id", this.outlet.status_id);
      if (this.outlet.venue_service_ids.length)
        this.outlet.venue_service_ids.forEach((venueServiceId, index) => {
          formData.append(`venue_service_ids[${index}]`, venueServiceId);
        });
      this.$http
        .post(
          "venues/outlets" +
            (this.outlet.id != null ? `/${this.outlet.id}` : ""),
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data; boundary=${form._boundary}",
            },
          }
        )
        .then((response) => {
          if (response.status == 200 && response.data.status == true) {
            let message = response.data.message;
            this.showSuccess(message);
            this.hideLoader();
            this.$emit("cancelOutlet");
          }
        })
        .catch((error) => {
          this.errorChecker(error);
        });
    },
    cancelOutlet() {
      this.$refs.form.resetValidation();
      this.$emit("cancelOutlet");
    },
  },
  data() {
    return {
      percentageRules: [
        (value) => {
          if (value && !/^\d+(\.\d+)?%?$/.test(value)) {
            return "Invalid percentage format";
          }
          return true;
        },
      ],
      facilities: [],
      valid: true,
      editFlag: false,
      outlet: {
        facility_id: null,
        is_public: 1,
        first_name: null,
        last_name: null,
        email: null,
        mobile: null,
        profile_image: null,
        username: null,
        password: null,
        password_confirm: null,
        hidePassword2: false,
        hidePassword1: false,
        venue_service_ids: [],
        facility_ids: [],
        facilityIds: [],
        status_id: 1,
        commission_percentage: null,
        description: null,
      },
    };
  },
};
</script>